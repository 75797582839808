.containerrr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.title_sections .h1 {
  font-size: 3rem;
}
.containerrr .main_sass.signup_full.bg_dark {
  height: 100vh;
  padding: 1rem 0;
}
.form-group.gender {
  margin-bottom: 1rem;
}
.form-group.gender label {
  display: block;
}
.form-group.gender select {
  width: 100%;
  height: 44px;
  border-radius: 4px !important;
  font-size: 14px;
  font-weight: 400;
}
.btn-pri {
  background-color: #5341c4 !important;
}
.containerrr .form_signup_one.signup_two {
  background-color: #000;
}

.containerrr .logo-wide {
  height: 52px;
}

@media (max-width: 640px) {
  .containerrr .form_signup_one.signup_two {
    padding-bottom: 64px;
  }
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 90%;
  font-size: 18px;
  padding: 2% 28px 1% !important;
}

h2.modal-title {
  font-size: 22px;
  margin: 12px 0;
}

.linked {
  color: #5341c4;
  /* font-weight: 500; */
}

.close-modal {
  border: none;
  background: none;
  font-weight: 600;
  font-size: 14px;
  margin: 24px auto 16px;
  background-color: #d74343;
  color: #fff;
  padding: 6px 12px;
  border-radius: 4px;
  float: right;
}

.extras {
  margin: 14px 0;
  font-size: 16px;
  font-weight: 300;
  /* line-height: 1.125; */
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

@media (min-width: 768px) {
  .ReactModal__Content.ReactModal__Content--after-open {
    width: 480px;
  }
}

.linked-white {
  color: #FFF;
  text-decoration: underline;
  font-weight: 600;
}